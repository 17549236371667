// COIN_dashboard\client\src\components\dashboard\ExportData.js

import React, { useState, useRef } from "react";
import { Request } from "../../requests/makeRequest";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
registerLocale("sv", sv);

export default function ExportDataPage({ selectedClient }) {
  //console.log("Exporting data for client:", selectedClient);
  const [MVDate, setMVDate] = useState(new Date());
  const [firstTranDate, setFirstTranDate] = useState(new Date());
  const [lastTranDate, setLastTranDate] = useState(new Date());

  return (
    <div className="Export-data-container">
      <div key="mv-export-data-box" className="Export-data-box">
        <h1 className="export-data-box-header">Exportera marknadsvärden</h1>
        <form key="MVDateReactKey" className="label-datepicker-wrapper">
          <label className="label-datepicker">Datum: </label>
          <DatePicker
            className="export-data-date-picker"
            key="MVDateReactKeyDatepicker"
            locale="sv"
            selected={MVDate}
            onChange={(date) => setMVDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </form>
        <div className="button-container">
          <button
            className="Export-data-button"
            onClick={() => {
              downloadMarketData(MVDate, selectedClient);
            }}
          >
            CSV
          </button>
          <button
            className="Export-data-button"
            onClick={() => {
              downloadMarketDataPDF(MVDate, selectedClient);
            }}
          >
            PDF
          </button>
        </div>
        <p>
          Observera att värdering av vissa finansiella instrument kan vara
          fördröjd
        </p>
      </div>

      <div key="tran-export-data-box" className="Export-data-box">
        <h1 className="export-data-box-header">Exportera transaktioner</h1>
        <form key="firstTranDateReactKey" className="label-datepicker-wrapper">
          <label className="label-datepicker">Från: </label>
          <DatePicker
            className="export-data-date-picker"
            key="firstTranDateReactKeyDatePicker"
            locale="sv"
            selected={firstTranDate}
            onChange={(date) => setFirstTranDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </form>
        <form key="lastTranDateReactKey" className="label-datepicker-wrapper">
          <label className="label-datepicker">Till: </label>
          <DatePicker
            className="export-data-date-picker"
            locale="sv"
            key="lastTranDateReactKeyDatePicker"
            selected={lastTranDate}
            onChange={(date) => setLastTranDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </form>
        <div className="button-container">
          <button
            className="Export-data-button"
            onClick={() => {
              downloadTransactions(firstTranDate, lastTranDate, selectedClient);
            }}
          >
            CSV
          </button>
          <button
            className="Export-data-button"
            onClick={() => {
              downloadTransactionsPDF(
                firstTranDate,
                lastTranDate,
                selectedClient
              );
            }}
          >
            PDF
          </button>
        </div>
      </div>
    </div>
  );
}

function downloadTransactions(firstDate, lastDate, selectedClient) {
  console.log("Exporting data for client:", selectedClient);
  if (!firstDate || !lastDate) {
    alert("Välj ett datumintervall");
    return;
  }

  // Format dates to 'yyyy-MM-dd' format
  const formattedFirstDate = firstDate.toISOString().substring(0, 10);
  const formattedLastDate = lastDate.toISOString().substring(0, 10);
  let filename = `transaktioner_${formattedFirstDate}_${formattedLastDate}.csv`; // Constructed filename with date range

  let req = new Request(
    (params) => {
      let query = new URLSearchParams();
      query.append("start", params.firstDate.toISOString().substring(0, 10));
      query.append("end", params.lastDate.toISOString().substring(0, 10));
      query.append("selectedClient", selectedClient);

      return fetch(`/api/export/transactions?${query.toString()}`, {
        method: "GET",
      });
    },
    {
      firstDate: firstDate,
      lastDate: lastDate,
      selectedClient: selectedClient,
    }, // params
    false // parseResponse
  );
  req
    .makeRequest()
    .then((res) => {
      if (!res.ok) {
        throw Error();
      } else if (res.status === 204) {
        alert("Inga transaktioner för valt datumintervall");
        return;
      }

      res.text().then((text) => {
        const BOM = "\uFEFF";
        const blob = new Blob([BOM + text], { type: "text/csv;charset=utf-8" }); // To ensure Swedish characters in the CSV.

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    })
    .catch((err) => {
      alert("Kunde inte ladda ner data");
    });
}

function downloadTransactionsPDF(firstDate, lastDate, selectedClient) {
  if (!firstDate || !lastDate) {
    alert("Välj ett datumintervall");
    return;
  }

  // Format dates to 'yyyy-MM-dd' format
  const formattedFirstDate = firstDate.toISOString().substring(0, 10);
  const formattedLastDate = lastDate.toISOString().substring(0, 10);
  let filename = `transaktioner_${formattedFirstDate}_${formattedLastDate}.pdf`; // Constructed filename with date range

  let req = new Request(
    (params) => {
      let query = new URLSearchParams();
      query.append("start", params.firstDate.toISOString().substring(0, 10));
      query.append("end", params.lastDate.toISOString().substring(0, 10));
      query.append("selectedClient", selectedClient);

      return fetch(`/api/export/transactionsPDF?${query.toString()}`, {
        method: "GET",
      });
    },
    {
      firstDate: firstDate,
      lastDate: lastDate,
      selectedClient: selectedClient,
    }, // params
    false // parseResponse
  );
  req
    .makeRequest()
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      // Handle 204 No Content
      if (res.status === 204) {
        alert("Inga transaktioner för valt datumintervall"); // Alert the user
        return;
      }

      // Continue only if there is content
      return res.arrayBuffer(); // Return the arrayBuffer for binary data
    })
    .then((buffer) => {
      // If buffer is null (due to 204), stop execution
      if (!buffer) return;

      const blob = new Blob([buffer], { type: "application/pdf" }); // Create Blob
      const url = window.URL.createObjectURL(blob); // Create object URL

      const a = document.createElement("a");
      a.href = url;
      a.download = filename || "download.pdf"; // Use filename or fallback
      a.click(); // Trigger the download

      window.URL.revokeObjectURL(url); // Clean up the object URL
    })
    .catch((err) => {
      console.error("Error downloading the PDF file:", err);
      alert("Kunde inte ladda ner PDF"); // Handle errors gracefully
    });
}

function downloadMarketData(date, selectedClient) {
  if (!date || !(date instanceof Date)) {
    alert("Välj ett datum");
    return;
  }
  console.log("date", date.toString());

  // Format the date to 'yyyy-MM-dd' format
  const formattedDate = date.toISOString().substring(0, 10);
  let filename = `marknadsvärden_${formattedDate}.csv`; // Constructed filename with date

  let req = new Request(
    (params) => {
      let query = new URLSearchParams();
      query.append("date", params.date.toISOString().substring(0, 10));
      query.append("selectedClient", selectedClient);
      return fetch(`/api/export/marketValues?${query.toString()}`, {
        method: "GET",
      });
    },
    { date: date, selectedClient: selectedClient }, // params
    false // parseResponse
  );
  req
    .makeRequest()
    .then((res) => {
      if (!res.ok) {
        throw Error();
      }

      res.text().then((text) => {
        const BOM = "\uFEFF";
        const blob = new Blob([BOM + text], { type: "text/csv;charset=utf-8" }); // To ensure Swedish characters in the CSV.

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    })
    .catch((err) => {
      alert("Kunde inte ladda ner data");
    });
}

function downloadMarketDataPDF(date, selectedClient) {
  if (!date || !(date instanceof Date)) {
    alert("Välj ett datum");
    return;
  }

  // Format the date to 'yyyy-MM-dd' format
  const formattedDate = date.toISOString().substring(0, 10);
  let filename = `marknadsvärden_${formattedDate}.pdf`; // Constructed filename with date

  let req = new Request(
    (params) => {
      let query = new URLSearchParams();
      query.append("date", params.date.toISOString().substring(0, 10));
      query.append("selectedClient", selectedClient);
      return fetch(`/api/export/marketValuesPDF?${query.toString()}`, {
        method: "GET",
      });
    },
    { date: date, selectedClient: selectedClient }, // params
    false // parseResponse
  );
  req
    .makeRequest()
    .then((res) => {
      if (!res.ok) {
        throw Error();
      } else if (res.status === 204) {
        alert("Inga transaktioner för valt datumintervall");
        return;
      }

      // Force the response to be interpreted as binary
      return res.arrayBuffer(); // Return the arrayBuffer for binary data
    })
    .then((buffer) => {
      const blob = new Blob([buffer], { type: "application/pdf" }); // Create the Blob
      const url = window.URL.createObjectURL(blob); // Create object URL for the Blob

      const a = document.createElement("a");
      a.href = url;
      a.download = filename || "download.pdf"; // Use the filename provided or fallback
      a.click(); // Trigger the download programmatically

      window.URL.revokeObjectURL(url); // Clean up the object URL
    })
    .catch((err) => {
      console.error("Error downloading the PDF file:", err);
      alert("Kunde inte ladda ner PDF"); // Handle errors gracefully
    });
}

//export default ExportDataPage;
